import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Annuitaetendarlehen = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader
                h1Title="Annuitätendarlehen – Tipps zur häufigsten Finanzierungsform der Immobilienwelt"
                showCalc={false}
            />
            <Article>
                <p>
                    Du suchst gerade nach der passenden{" "}
                    <Link to="/artikel/wohnkredit/" target="_blank" rel="noreferrer noopener">
                        Finanzierung
                    </Link>{" "}
                    für deine Wohnung, verlierst aber bei all den Möglichkeiten den Überblick. Darlehen oder Kredit,
                    Bausparvertrag, Tilgungsdarlehen oder Annuitätendarlehen – es gibt tausende Arten und du willst
                    natürlich die beste finden. Jeder kann sich einen Überblick über Immobilienfinanzierung machen, wir
                    möchten dabei helfen. Weil Annuitätendarlehen die häufigste Form von Immobilienfinanzierungen ist,
                    möchten wir dir erklären, was es damit auf sich hat.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was bedeutet Annuitätendarlehen? Ein Überblick</h2>
                <p>
                    Annuitätendarlehen sind eigentlich eine Spielform von Tilgungsdarlehen. Bei diesen Darlehensformen
                    geht es um nichts anderes, als dass die Summe des geliehenen Geldes noch während der
                    Darlehenslaufzeit getilgt wird. Nur sehr wenige Darlehen sind „endfällig“, das heißt, werden nicht
                    getilgt, sondern erst nach Ablauf der Laufzeit zurückgezahlt. Der Unterschied zwischen
                    Tilgungsdarlehen und Annuitätendarlehen besteht darin, dass du bei Annuitätendarlehen mit konstanten
                    Raten rechnen kannst. Annuitäten werden schlicht die Raten genannt, welche sich aus zwei Teilen
                    zusammensetzen: dem Zins- und dem Tilgungsanteil.
                </p>
                <p>
                    Der Zinsanteil verändert sich bei dieser Darlehensform während der Laufzeit zugunsten des
                    Tilgungsanteils. Und am Ende der Laufzeit ist deine Schuld vollständig getilgt.
                </p>
                <hr />

                <h2>Welche Vorteile hat ein Annuitätendarlehen?</h2>
                <p>
                    Bei dieser Form der Immobilienfinanzierung zahlst du jedes Jahr den gleichen Betrag zurück und
                    kannst so sehr genau planen. Der Begriff geht auf das lateinische Wort “annus” zurück, das “Jahr”
                    bedeutet. Somit weißt du von Anfang an, was dich jedes Jahr erwartet. Das kann ein großer Vorteil
                    sein, weil du die Summe gut überblicken kannst. Die Rate verändert sich nicht und bleibt jedes Jahr
                    gleich, egal was kommt. Das Annuitätendarlehen bietet also vor allem eines: Planungssicherheit.
                </p>
                <p>
                    Ein anderer Vorteil ist außerdem die laufende{" "}
                    <Link to="/artikel/tilgung/" target="_blank" rel="noreferrer noopener">
                        Tilgung
                    </Link>
                    . Sie erfolgt entweder in Form einer Kapital- oder einer Pauschalrate. Je länger die Tilgung läuft,
                    desto kleiner wird der Kreditbetrag. Dein Darlehen kann auch vor der vereinbarten Laufzeit von dir
                    getilgt werden. Aufgrund dieser Vorteile eignet sich das Annuitätendarlehen für die meisten
                    Immobilienfinanzierungen sehr gut. Aber jede Situation ist unterschiedlich, vielleicht gibt es für
                    dich auch eine bessere Alternative?
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Kreditvergleich anfordern!
                    </a>
                </p>
                <hr />

                <h2>Gibt es gute Alternativen?</h2>
                <p>
                    Das Tilgungsdarlehen ist eine gute Alternative zum Annuitätendarlehen. Allerdings ist hier die
                    Laufzeit oft länger und die Raten sind am Beginn ziemlich hoch. Außerdem sinken die Zinsen immer
                    mehr, wenn du dein Darlehen abbezahlst. Je geringer die verbleibende Summe, desto geringer die{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>
                    . Ansonsten unterliegen sie keinen Veränderungen. Im ersten Jahr machen die Zinsen noch einen großen
                    Teil der Rate aus, aber der Anteil der Rate wird im Laufe der Zeit immer kleiner. Die Zinsen sinken
                    immer mehr, wenn du dein Darlehen abbezahlst. Je geringer die verbleibende Summe, desto geringer die
                    Zinsen. Ansonsten unterliegen sie keinen Veränderungen. Im ersten Jahr machen die Zinsen noch einen
                    großen Teil der Rate aus, aber der Anteil der Rate wird im Laufe der Zeit immer kleiner. Das hat
                    viele Vorteile, aber man muss es sich eben von Beginn an leisten können.
                </p>
                <p>
                    Eine andere Option sind sogenannte endfällige Darlehen, die auch als Fälligkeitsdarlehen bezeichnet
                    werden. Anders als beim Tilgungs- oder Annuitätendarlehen werden während der Laufzeit nur die Zinsen
                    regelmäßig bezahlt und erst am Ende schließlich der Darlehensbetrag in voller Höhe. Das funktioniert
                    dann meistens über einen Bausparvertrag, den Verkauf von Wertpapieren oder einer
                    Kapitallebensversicherung. Auch wenn diese Form ungewöhnlich und riskant ist, stellt sie für einige
                    Personen die beste Alternative dar.
                </p>
                <hr />

                <h2>Was du also beachten solltest</h2>
                <p>
                    Wir haben noch einen Tipp für dich: Du kannst dir deine Annuität online berechnen lassen. Auch unser{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    soll dir dabei helfen, das richtige Finanzierungsmodell für deine Traumimmobilie zu finden.
                </p>
                <p>
                    Das Annuitätendarlehen ist also für alle das richtige Modell, die ein regelmäßiges und
                    vorhersehbares Einkommen beziehen. Wenn du weißt, dass deine finanziellen Verhältnisse sich in den
                    nächsten Jahren nicht stark verändern werden, ist es für dich am besten, dir eine Darlehenssumme
                    zurechtzulegen, die genauso konstant bleibt wie dein Einkommen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"annuitaetendarlehen"}></BreadcrumbList>
            <ArticleStructuredData
                page={"annuitaetendarlehen"}
                heading={"Annuitätendarlehen – Tipps zur häufigsten Finanzierungsform der Immobilienwelt"}
            />
        </Layout>
    );
};

export default Annuitaetendarlehen;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.annuitaetendarlehen", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
